import { PdfUpsellTestVariant } from 'constants/featureDefinitions';
import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'store';
import * as speechify from 'lib/speechify';
import { actions as importActions } from 'store/import';

interface UpsellResult {
  shouldUpsell: boolean;
  currentFileCount: number;
}

interface UsePdfUpsellReturn {
  maxPdfCount: number;
  checkForUpsell: (fileCount?: number) => Promise<boolean>;
  openUpsellModal: () => void;
  closeUpsellModal: () => void;
}

export function usePdfUpsell(): UsePdfUpsellReturn {
  const dispatch = useDispatch();
  const pdfUpsellVariant = useFeatureVariant(FeatureNameEnum.PDF_UPSELL);
  const maxPdfCount = !pdfUpsellVariant.isLoading && pdfUpsellVariant.variant === PdfUpsellTestVariant.UPSELL_THREE ? 3 : 5;
  const user = useSelector(state => state.auth.user);
  const { items: libraryItems } = useSelector(state => state.library);

  const upsellPromise = useMemo<Promise<UpsellResult>>(async () => {
    if (!user?.uid || !speechify.canUpgrade(user)) {
      return { shouldUpsell: false, currentFileCount: Number.MIN_SAFE_INTEGER };
    }

    const currentPDFCount = Math.max(await speechify.getPDFCount(user.uid), libraryItems.length);
    return { shouldUpsell: currentPDFCount >= maxPdfCount, currentFileCount: currentPDFCount };
  }, [user, libraryItems.length, maxPdfCount]);
  const upsellRef = useRef(upsellPromise);
  upsellRef.current = upsellPromise;

  const checkForUpsell = useCallback(
    async (fileCount = 1): Promise<boolean> => {
      const { shouldUpsell, currentFileCount } = await upsellRef.current;
      const showUpsellModal = shouldUpsell || currentFileCount + fileCount > maxPdfCount;

      if (showUpsellModal) {
        dispatch(importActions.closeImportDialog());
        dispatch(importActions.setUpsellModalOpen(true));
      }

      return showUpsellModal;
    },
    [maxPdfCount, dispatch]
  );

  const hookReturn = useMemo<UsePdfUpsellReturn>(
    () => ({
      checkForUpsell,
      maxPdfCount,
      openUpsellModal: () => dispatch(importActions.setUpsellModalOpen(true)),
      closeUpsellModal: () => dispatch(importActions.setUpsellModalOpen(false))
    }),
    [checkForUpsell, maxPdfCount, dispatch]
  );

  return hookReturn;
}
