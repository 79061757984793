import { ImportOptions } from 'components/library/import/upload/import';
import { Nullable } from 'utils/types';

import { BaseListenableContent, ContentMetaType, ContentType } from '../base';
import { ImportCompleteCallback } from '../utils';

export abstract class BaseFileListenableContent extends BaseListenableContent {
  public readonly file: File;
  public readonly contentType: ContentType = ContentType.file;
  public readonly itemId: Promise<string>;

  private _itemIdPromiseResolver!: (value: string) => void;

  public readonly folderId: Promise<string | undefined>;
  private _folderIdPromiseResolver!: (value: string | undefined) => void;

  public readonly onImportCompleteCallback: Promise<ImportCompleteCallback>;
  private _onImportCompleteCallbackPromiseResolver!: (value: ImportCompleteCallback) => void;

  constructor(file: File) {
    super();
    this.file = file;
    this.itemId = new Promise<string>(resolve => {
      this._itemIdPromiseResolver = resolve;
    });
    this.folderId = new Promise<string | undefined>(resolve => {
      this._folderIdPromiseResolver = resolve;
    });
    this.onImportCompleteCallback = new Promise<ImportCompleteCallback>(resolve => {
      this._onImportCompleteCallbackPromiseResolver = resolve;
    });
  }

  public setImportOptions = ({
    callback,
    folderId
  }: Pick<ImportOptions, 'folderId'> & {
    callback: ImportCompleteCallback;
  }) => {
    this._onImportCompleteCallbackPromiseResolver(callback);
    this._folderIdPromiseResolver(folderId);
  };

  get title(): string {
    return this._titleOverride || this.file.name;
  }

  abstract get metaType(): ContentMetaType;

  override onItemIdReady(itemId: string): void {
    super.onItemIdReady(itemId);
    // this is used by useListenableContentItemId state to trigger rerender when item ID is ready,
    // which is useful for hiding/displaying features that depends on item ID readiness.
    this._itemIdPromiseResolver(itemId);
  }

  override get wordCount(): Nullable<number> {
    // TODO(overhaul): Implement wordCount for instant listening
    return null;
  }
}
