import React, { useEffect } from 'react';

import PdfSplash from 'assets/icons/pdfSplash';
import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import * as speechify from 'lib/speechify';
import { logSegmentEvent } from 'utils/analytics';

type PDFUpsellModalProps = {
  onClose: () => void;
  maxPDFCount: number;
  open?: boolean;
};

const PDFUpsellModal: React.FC<PDFUpsellModalProps> = ({ onClose, maxPDFCount, open = true }) => {
  useEffect(() => {
    logSegmentEvent('web_app_pdf_upsell_shown');
  }, []);

  const handleUpgrade = () => {
    window.open(speechify.getUpgradeURL('web_app_pdf_limit_popup'), '_blank');
    logSegmentEvent('web_app_pdf_upsell_upgrade_clicked');
    onClose();
  };

  return (
    <ModalComponent open={open} showCloseButton onClose={onClose} classNames="min-w-min" dialogClassNames="z-2000">
      <div className="flex h-full w-fullCard flex-none flex-col justify-center sm:px-14 sm:py-10 md:px-10 md:py-10">
        <div className="flex flex-col items-center">
          <PdfSplash />
          <h3 className="leading-12 mb-6 mt-4 text-center font-recoleta text-4xl font-bold text-glass-700">
            Upgrade to upload
            <br />
            unlimited PDFs
          </h3>
          <p className="mb-8 text-center leading-6 text-glass-600">
            With Speechify Basic, users can only have a maximum of
            <br />
            {maxPDFCount} PDFs in their library. To upload new ones, you can
            <br />
            delete some of your existing PDFs.
          </p>
          <p className="mb-8 text-center leading-6 text-glass-600">
            Upgrade to Speechify Premium to upload and listen to
            <br />
            unlimited PDFs with human-like voices.
          </p>

          <Button title="Upgrade" onClick={handleUpgrade} className="mt-2 font-ABCDiatype text-lg font-medium" style={{ minWidth: 328 }} />
        </div>
      </div>
    </ModalComponent>
  );
};

export default PDFUpsellModal;
