import React from 'react';

export function SpeechifyLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.03 9.62l.6-.78c.27-.35.7-.52 1.14-.43a.63.63 0 01.45.83c-.5 1.41-.65 2.35-.47 2.8.74-.9 4.5-6.74 5.7-7.02.4-.1.79.16.88.57.03.14.02.28-.02.41-1.13 3.33-1.6 5.35-1.43 6.04.6-.85 1.47-2 2.65-3.45a1.8 1.8 0 011.81-.63c.6.14.96.74.83 1.34v.02c-.41 1.73-.57 2.74-.46 3.04l.14-.11a2.7 2.7 0 011.89-.59l5.26.4-3.49.4c-.97.12-1.86.62-2.48 1.4l-.83 1.04c-.28.34-.7.53-1.13.5a.96.96 0 01-.86-.79c-.2-1.06-.24-2.3-.1-3.75-1.05 1.6-1.95 3.9-2.72 6.9-.14.52-.6.88-1.11.88-.5 0-.91-.4-.92-.91l-.24-7.68-2.23 4.5c-.23.47-.74.72-1.24.6a1.62 1.62 0 01-1.17-1l-.25-.65a2.73 2.73 0 00-2.1-1.72L0 11.27l2.32-.17a3.75 3.75 0 002.71-1.48z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
