import { importSDK } from 'components/experience/readers/newsdk';
import { ReadingInfo } from 'components/experience/readers/newsdk/ReadingInfo';
import { TextImport, UrlImport } from 'interfaces/import';
import { getRootFolder } from 'lib/speechify';
import { Callback } from 'lib/speechify/adaptors/lib/typeAliases';
import { ListenableContent } from 'modules/sdk/lib';
import { getListeningOverhaulImportTask } from 'modules/sdk/lib/facade/listenableContent/factory';
import { humanFileSize } from 'utils';
import { logSegmentEvent } from 'utils/analytics';
import type { Nullable } from 'utils/types';

import { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';

import { AUTO_IMPORT_LOCAL_MIME_TYPES, AUTO_IMPORT_REMOTE_MIME_TYPES, CLASSIC_READER_MIME_TYPES } from '../constants';
import { getRecordType, InstantListeningSupportedData, isTextImport, isUrlImport, matchMimeType } from '../utils';
import { convert } from './convert';

export type ImportSource = {
  name: string;
  mimeType: string;
  data: string | File | TextImport | UrlImport;
};

export type NonInstantListeningImportSource = ImportSource & {
  // InstantListening data is already handled and shouldn't go to this function.
  data: Exclude<ImportSource['data'], InstantListeningSupportedData>;
};

interface ImportData {
  data: File | string | TextImport | UrlImport;
  name: string;
  mimeType: string;
  source?: string;
  size: number;
}

type LegacyImportOptions = {
  isListeningOverhaul: false;
};

type ListeningOverhaulImportOptions = {
  isListeningOverhaul: true;
  listenableContent?: Nullable<ListenableContent>;
};

type AdditionalImportOptions = LegacyImportOptions | ListeningOverhaulImportOptions;

export type ImportOptions = {
  folderId?: string;
  instantListening: boolean;
} & AdditionalImportOptions;

type BaseImportState = {
  imported: Promise<string>;
  importSource: ImportSource;
};

export type LegacyImportState = BaseImportState & {
  completed: Promise<ReadingInfo>;
};

export type ListeningOverhaulImportState = BaseImportState & {
  completed: Promise<ListenableContent>;
};

export type ImportState = LegacyImportState | ListeningOverhaulImportState;

async function getImportSource({ data, ...metadata }: ImportData, shouldConvert: boolean): Promise<ImportSource> {
  if (isTextImport(data)) {
    return { name: data.title, data, mimeType: metadata.mimeType };
  }

  if (isUrlImport(data)) {
    const { isPDF } = await importSDK();
    return { name: '', data, mimeType: (await isPDF(data.url)) ? 'pdf' : 'web_link' };
  }

  if (!shouldConvert) {
    return { ...metadata, data };
  }

  const conversionResult = await convert(data, metadata.source);

  return {
    ...conversionResult,
    mimeType: conversionResult.mime || metadata.mimeType,
    data: conversionResult.ssl_url,
    name: decodeURIComponent(conversionResult.name)
  };
}

async function getImportTask(
  importSource: ImportSource,
  {
    folderId
  }: {
    folderId: string | undefined;
  },
  callback: Callback<SpeechifyURI>
): Promise<ReadingInfo> {
  const { data } = importSource;
  const { isPDF, createClassicReaderFromText, createBookReaderFromUrl, createClassicReaderFromUrl, createBookReaderFromFile } = await importSDK();

  if (isTextImport(data)) {
    return createClassicReaderFromText({ ...data, folderId }, callback);
  }

  if (isUrlImport(data)) {
    const createReaderFromUrl = (await isPDF(data.url)) ? createBookReaderFromUrl : createClassicReaderFromUrl;
    return createReaderFromUrl({ folderId, url: data.url }, callback);
  }

  if (typeof data === 'string') {
    const createReaderFromUrl = CLASSIC_READER_MIME_TYPES.includes(importSource.mimeType) ? createClassicReaderFromUrl : createBookReaderFromUrl;
    return createReaderFromUrl({ folderId, title: importSource.name, url: data }, callback);
  }

  return createBookReaderFromFile({ folderId, file: data }, callback);
}

export async function importItem({ data, ...metadata }: ImportData, { folderId: currentFolderId, ...importOptions }: ImportOptions): Promise<ImportState> {
  if (metadata.size / 1024 ** 2 > 20) {
    logSegmentEvent('web_app_large_document_imported', {
      size: humanFileSize(metadata.size),
      type: metadata.name.split('.').slice(-1)[0]
    });
  }

  const importMimeTypes = data instanceof File ? AUTO_IMPORT_LOCAL_MIME_TYPES : AUTO_IMPORT_REMOTE_MIME_TYPES;
  const shouldConvert = !importMimeTypes.some(mimeType => matchMimeType(mimeType, metadata.mimeType));
  const importSource = await getImportSource({ data, ...metadata }, shouldConvert);
  const recordType = getRecordType(metadata.mimeType).toLowerCase();

  // If the currentFolderId is 'trash', we should import the item to the root folder
  // because the trash folder is a custom web app folder and not part of the SDK.
  const folderId = currentFolderId === 'trash' ? await getRootFolder() : currentFolderId;

  let resolveItemId: (itemId: string) => void;
  let reject: (reason?: unknown) => void;

  const importItemPromise = new Promise<string>((res, rej) => {
    resolveItemId = res;
    reject = rej;
  });

  const handleImportResult: Callback<SpeechifyURI> = importResult => {
    importResult.ifSuccessful(uri => {
      const eventData: Record<string, string> = {};

      if (isUrlImport(data)) {
        eventData.type = metadata.mimeType;
        eventData.importedWebLink = data.url;
        eventData.importedHostName = new URL(data.url).hostname;
      } else if (isTextImport(data)) {
        eventData.type = metadata.mimeType;
      } else {
        eventData.type = recordType;
      }

      if (metadata.source) {
        eventData.source = metadata.source;
      }

      logSegmentEvent('web_app_document_imported', eventData);
      resolveItemId(uri.id);
    });

    importResult.onFailure(result => {
      logSegmentEvent('web_app_error_importing_document', { type: recordType, error: result.error });
      reject(result.error);
    });
  };

  const task =
    // TODO(overhaul): import on listening overhaul starts when user gets to the instant listening, but when instant listening doesn't happen (multiple files upload)
    // then import will never get kickstarted, so we use the legacy implementation here to start the multiple file imports.
    importOptions.isListeningOverhaul && importOptions.instantListening
      ? getListeningOverhaulImportTask(importSource, { folderId, ...importOptions }, handleImportResult)
      : getImportTask(importSource, { folderId }, handleImportResult);

  task.catch(error => {
    logSegmentEvent('web_app_error_importing_document', { type: recordType, error });
    reject(error);
  });

  return {
    imported: importItemPromise,
    completed: task,
    importSource
  } as ImportState;
}
