import React from 'react';
import * as speechify from 'lib/speechify';
import { useSelector } from 'store';
import { selectors as importSelectors } from 'store/import';
import PDFUpsellModal from 'components/item/PdfUpsell';
import { usePdfUpsell } from './hooks/usePdfUpsell';

export function PdfUpsell() {
  const user = useSelector(state => state.auth.user);
  const { isUpsellModalOpen } = useSelector(importSelectors.getImportState);
  const instantListening = useSelector(state => state.mainPage.instantListening);
  const { maxPdfCount, closeUpsellModal } = usePdfUpsell();

  const handleClose = () => {
    closeUpsellModal();
  };

  if (user && speechify.canUpgrade(user) && !instantListening) {
    return <PDFUpsellModal open={isUpsellModalOpen} onClose={handleClose} maxPDFCount={maxPdfCount} />;
  }

  return <></>;
}
