import React, { Fragment, useEffect, useMemo, useState } from 'react';

import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import TextInput, { SelectInput } from 'components/elements/TextInput';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'coun... Remove this comment to see the full error message
import * as countryCodes from 'country-codes-list';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IUser } from 'interfaces';
import * as speechify from 'lib/speechify';
import { auth } from 'lib/speechify';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';
import { getCustomAccountSetting, setCustomAccountSetting } from 'utils/baseAccountSettings';
import { sendAppLinkEmail, sendAppLinkTwilioText } from 'utils/comm';
import { logError } from 'utils/errorLogging';
import * as yup from 'yup';

import { Tab } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';

type ModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  type: 'tel' | 'email';
  variant?: string;
};

type ContentProps = {
  type: 'tel' | 'email' | 'both';
  variant?: string;
  setModal?: React.Dispatch<React.SetStateAction<string>>;
};

type submitResultType = {
  success: boolean;
  error: boolean;
  submittedFormData: {
    tel?: string;
    country?: string;
    email?: string;
  };
};

export const MobileAppSendLinkModalContent = ({ type, variant, setModal }: ContentProps) => {
  const { t } = useTranslation('common');
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  const [selectedType, selectType] = useState(type === 'both' ? 'email' : type);
  // ESLint: 'showNudge' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNudge, setShowNudge] = useState(true);

  const telSchema = yup.object().shape({
    tel: yup
      .string()
      .matches(/^(\d{10})$/, t('Invalid phone number format'))
      .required(t('A valid phone number is required')),
    country: yup
      .string()
      .matches(/^(\+\d{1,3})/, t('Invalid country code format'))
      .required(t('A valid country code is required'))
  });

  type TelFormFields = { tel: string; country: string };

  const emailSchema = yup.object().shape({
    email: yup.string().email().required(t('valid email required'))
  });

  const countryCodesList: Array<{ country: string; code: string }> = useMemo(() => {
    return countryCodes.customArray({ country: '{countryNameEn}', code: '+{countryCallingCode}' });
  }, []);

  type EmailFormFields = { email: string };

  const countryCodesOptions = useMemo(() => {
    return countryCodesList.map(({ country, code }) => ({
      label: () => (
        <div>
          {country} <span className="text-gray-400">{code}</span>
        </div>
      ),
      value: code
    }));
  }, [countryCodesList]);

  const {
    formState: { errors, isSubmitted },
    handleSubmit: handleSubmitWrapper,
    register,
    watch,
    setValue,
    getValues
  } = useForm<TelFormFields | EmailFormFields>({
    resolver: yupResolver(selectedType === 'tel' ? telSchema : emailSchema),
    shouldUnregister: true
  });

  useEffect(() => {
    if (selectedType === 'tel') {
      setValue('country', countryCodesList[0].code, { shouldValidate: true });
      setValue('tel', '', { shouldValidate: true });
    } else if (selectedType === 'email') {
      // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      setValue('email', auth.currentUser?.email, { shouldValidate: true });
    }
    // ESLint: React Hook useEffect has missing dependencies: 'countryCodesList' and 'setValue'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    if (speechify.auth.currentUser?.uid) {
      getCustomAccountSetting('clickedStartListening')
        .then(setting => {
          setShowNudge(setting !== 'true');
        })
        .catch(e => {
          console.warn('Could not get account settings', speechify.auth.currentUser, e);
        });
    }
    // ESLint: React Hook useEffect has an unnecessary dependency: 'speechify.auth.currentUser'. Either exclude it or remove the dependency array. Outer scope values like 'speechify.auth.currentUser' aren't valid dependencies because mutating them doesn't re-render the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speechify.auth.currentUser]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState<submitResultType>({ success: false, error: false, submittedFormData: {} });

  const handleSubmit = (data: TelFormFields | EmailFormFields) => {
    setSubmitting(true);
    setSubmitResult({ success: false, error: false, submittedFormData: {} });

    logSegmentEvent('web_app_install_link_requested', { mode: selectedType === 'tel' ? 'message' : 'email' });

    if (selectedType === 'email') {
      const emailFormFields = data as EmailFormFields;

      sendAppLinkEmail(emailFormFields.email)
        .then(() => {
          setSubmitResult({ ...submitResult, success: true, submittedFormData: emailFormFields });
          setSubmitting(false);
          setCustomAccountSetting('mobileAppInstalled', 'true');
          logSegmentEvent('web_app_install_link_sent', { mode: 'email' });
        })
        .catch(error => {
          logError(error);
          setSubmitResult({ ...submitResult, error: true, submittedFormData: data });
          setSubmitting(false);
        });
    } else if (selectedType === 'tel') {
      const telFormFields = data as TelFormFields;

      sendAppLinkTwilioText(telFormFields)
        .then(({ success }) => {
          if (success) {
            setSubmitResult({ ...submitResult, success: true, submittedFormData: telFormFields });
            setCustomAccountSetting('mobileAppInstalled', 'true');
            logSegmentEvent('web_app_install_link_sent', { mode: 'message' });
          } else {
            setSubmitResult({ ...submitResult, error: true, submittedFormData: telFormFields });
          }

          setSubmitting(false);
        })
        .catch(error => {
          logError(error);
          setSubmitResult({ ...submitResult, error: true, submittedFormData: telFormFields });
          setSubmitting(false);
        });
    }
  };

  const watchFields = watch();
  const telWatchFields = watchFields as TelFormFields;
  const emailWatchFields = watchFields as EmailFormFields;
  const telErrors = errors as FieldErrorsImpl<TelFormFields>;
  const emailErrors = errors as FieldErrorsImpl<EmailFormFields>;

  const submitButton = useMemo(() => {
    const { success, error, submittedFormData } = submitResult;

    if (isSubmitting) {
      return <Button full className="mb-4 h-12 py-2.5 text-lg font-semibold" isLoading type="submit" onClick={() => {}} />;
    }

    if (
      (selectedType === 'tel' && telWatchFields.tel === submittedFormData.tel && telWatchFields.country === submittedFormData.country) ||
      (selectedType === 'email' && emailWatchFields.email === submittedFormData.email)
    ) {
      if (success) {
        return (
          <Button
            full
            className=" whitespace-pre-line border-[#0fa41e] bg-[#f6fef7] py-2.5 font-['ABCDiatype'] text-lg font-bold text-[#0fa41e]"
            type="submit"
            onClick={() => {}}
          >
            <CheckIcon className=" inline-block h-6 w-5 font-bold" aria-hidden="true" />
            {t('send_email_sms_success', { type: selectedType === 'tel' ? 'text' : 'email' })}
          </Button>
        );
      }

      if (error) {
        return (
          <Fragment>
            <div className="flex py-2 text-sm text-red-400">
              <XCircleIcon className="mr-1 inline-block h-5 w-5" aria-hidden="true" />
              {t('send_email_sms_failure', { type: selectedType === 'tel' ? 'text' : 'email' })}
            </div>
            <Button full className="mb-4 py-2.5 text-lg font-semibold" type="submit" onClick={handleSubmitWrapper(handleSubmit)}>
              {t('Retry')}
            </Button>
          </Fragment>
        );
      }
    }
    return (
      <Button
        full
        className={twMerge('py-[5px] text-base font-bold', variant === 'modal' ? 'py-[0.7rem] text-lg' : '')}
        title={t(selectedType === 'tel' ? 'Text me a Link' : 'Email me a link')}
        type="submit"
        onClick={handleSubmitWrapper(handleSubmit)}
      />
    );
    // ESLint: React Hook useMemo has missing dependencies: 'emailWatchFields.email', 'handleSubmit', 'handleSubmitWrapper', 'selectedType', 't', 'telWatchFields.country', 'telWatchFields.tel', and 'variant'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, submitResult, watchFields]);

  return (
    <Fragment>
      {type === 'both' && (
        <Tab.Group
          onChange={index => {
            const availableTypes: ('email' | 'tel')[] = ['email', 'tel'];
            selectType(availableTypes[index]);
          }}
        >
          <Tab.List className="mb-4 flex w-full space-x-1 rounded-xl bg-glass-200">
            {['Email', 'Phone'].map(tabLabel => (
              <Tab
                className={({ selected }) =>
                  twMerge(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected ? 'bg-white text-blue-400 shadow ring-1' : 'text-glass-500 hover:bg-white/[0.12] hover:text-blue-700'
                  )
                }
                key={tabLabel.toLowerCase()}
              >
                {tabLabel}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      )}
      <div className={`checklist-subitem-wrapper mb-4 flex  flex-col  ${variant === 'modal' && 'checklist-modal-wrapper'} `}>
        {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Download the mobile app')}</h4>}

        <p>Please input your email address below and we will email you a link to install Speechify mobile app.</p>
        {selectedType === 'tel' && (
          <div className="mr-3 w-24">
            <SelectInput
              className={twMerge('w-24 py-3.5')}
              selected={getValues('country')}
              // ESLint: Unexpected any
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setSelected={(val: any) => setValue('country', val, { shouldValidate: true })}
              options={countryCodesOptions}
              error={telErrors.country}
              maxLength={10}
              name="country"
              placeholder={t('Country')}
            />
          </div>
        )}
        <div className="mb-3 w-full flex-grow">
          <TextInput
            className={twMerge('h-9  w-[100%] border-[#E4EAF1] text-[#1C2C40]', variant === 'modal' ? 'h-[48px]' : '')}
            error={isSubmitted && emailErrors}
            // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
            name={selectedType}
            type={selectedType}
            placeholder={t(selectedType === 'tel' ? 'Phone Number' : 'Email Address')}
            {...register(selectedType)}
          />
        </div>
        {submitButton}
        <p
          className={twMerge('checklist-subitem-terms text-xs text-[#587393]', variant === 'modal' ? 'mb-4 pb-0' : 'pb-0')}
          style={{ paddingBottom: variant === 'modal' ? 0 : '' }}
        >
          By continuing, you accept the{' '}
          <a className="underline" href="https://speechify.com/terms" target="_blank" rel="noopener noreferrer">
            terms of use
          </a>{' '}
          and{' '}
          <a className="underline" href="https://speechify.com/privacy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>
          .
        </p>

        {variant === 'subItem' && user.mobileAppInstalled && (
          <Button
            // ESLint: Do not pass children as props
            // eslint-disable-next-line react/no-children-prop
            children="Start Listening"
            onClick={() => {
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              setModal('');
              setCustomAccountSetting('clickedStartListening', 'true');
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

const MobileAppSendLinkModal = ({ show, setShow, type, variant }: ModalProps) => {
  const { t } = useTranslation('common');

  const handleCloseClick = () => {
    setShow(false);
  };

  return (
    <ModalComponent open={show} showCloseButton onClose={handleCloseClick} classNames="md:w-fullCard overflow-visible" dialogClassNames="z-2000">
      <div className="flex h-full w-full flex-none flex-col justify-center sm:px-14 sm:py-10 md:px-10 md:py-10">
        <div className="flex flex-col items-center">
          <h2 className="mb-4 mt-2 font-ABCDiatype text-2xl font-bold leading-8">{t('Get Speechify for Mobile')}</h2>
          <p className="onboarding-card-body mb-8 text-center leading-6">
            Please input your {type === 'tel' ? 'phone number' : 'email address'} below and we will {type === 'tel' ? 'text' : 'email'} you a link to install
            Speechify mobile app.
          </p>
          <MobileAppSendLinkModalContent variant={variant} type={type} />
        </div>
      </div>
    </ModalComponent>
  );
};

export default MobileAppSendLinkModal;
