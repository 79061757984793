import { auth } from 'lib/speechify';

export const sendAppLinkEmail = async (email: string) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  const idToken = await auth.currentUser.getIdToken();

  await fetch('/api/email/sendinstalllink', {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8', Authorization: `Bearer ${idToken}` },
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    body: JSON.stringify({ email: email || auth.currentUser.email })
  });
};

export const sendAppLinkTwilioText = async ({ country, tel }: $TSFixMe) => {
  const body = {
    country,
    tel,
    install_link: 'https://speechify.page.link/chrome-to-mobile'
  };

  const response = await fetch(`https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net/sendEcosystemAdoptionTextMessage`, {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(body)
  });

  const result = await response.json();

  if (!response.ok) {
    throw new Error(result.error_msg || result.message);
  }

  return result;
};
