import React, { useState } from 'react';

import { Stars } from 'assets/images';
import MobileAppSendLinkModal from 'components/ecosystem/mobileAppSendLinkModal';
import Button from 'components/elements/Button';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logSegmentEvent } from 'utils/analytics';

import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

export const CallToAction = () => {
  const { t } = useTranslation('common');

  const [isCollapsed, setCollapsed] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  const onGetMobileAppClick = (state: 'expanded' | 'minimized') => {
    logSegmentEvent('web_app_mobile_app_upsell_in_library_clicked', {
      state
    });
    setShowQrCode(true);
  };

  const onHideClick = () => {
    setCollapsed(true);
  };
  return (
    <div>
      <MobileAppSendLinkModal show={showQrCode} setShow={setShowQrCode} type="email" />
      <div className="overflow-hidden">
        <Transition
          show={!isCollapsed}
          appear={false}
          enter="transition-transform duration-75"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="transition-transform duration-700"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <div className="text-sm text-gray-700">
            <div className="relative top-8 text-right hover:text-gray-400">
              <a className="cursor-pointer align-middle" onClick={() => onHideClick()}>
                Hide <ChevronDownIcon className="inline w-4" aria-hidden="true" />
              </a>
            </div>
            <img className="w-3/4" src="/images/dashboard/mobile_app_upsell_cta_image.svg" />
            <h1 className="mb-1 font-recoleta text-xl font-semibold">{t('Want Speechify on your Phone?')}</h1>
            <p className="mb-3 text-gray-400">{t('You can scan printed text and listen to your library on the go')}</p>
            <Button
              className="w-full border-mauve0 font-semibold"
              color="alternate"
              title={t('Get mobile app')}
              onClick={() => onGetMobileAppClick('expanded')}
            />
            <div className="mt-3 grid grid-cols-6 place-content-start gap-2">
              <div>
                <a href="https://apps.apple.com/us/app/speechify-text-reader-news-pdf/id1209815023" rel="noreferrer noopener" target="_blank">
                  <img className="h-6 w-6" src="/images/appstore_alt.png" />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.cliffweitzman.speechify2&hl=en_IN&gl=US" rel="noreferrer noopener" target="_blank">
                  <img className="h-6 w-6" src="/images/playstore_alt.png" />
                </a>
              </div>
              <Stars isGolden />
            </div>
          </div>
        </Transition>
      </div>
      <div className="overflow-hidden">
        <Transition
          show={isCollapsed}
          appear={false}
          enter="transition-transform transition-opacity duration-500"
          enterFrom="transform translate-y-full opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-transform transition-opacity duration-75"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform translate-y-full opacity-0"
        >
          <div className="mx-4 mb-4 text-gray-700">
            <hr className="mb-4 border-gray-300" />
            <div className="flex cursor-pointer hover:text-gray-500" onClick={() => onGetMobileAppClick('minimized')}>
              <h3 className="mr-4 font-semibold">{t('Get Speechify for Mobile')}</h3>
              <img src="/images/dashboard/speechify_mobile_icon.svg" />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default CallToAction;
