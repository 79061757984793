import { useCallback } from 'react';

import { importSDK } from 'components/experience/readers/newsdk';
import { ErrorSource } from 'constants/errors';
import { WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import { FeatureNameEnum, getFeatureVariant } from 'hooks/useFeatureFlags';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES, TextImport, UrlImport } from 'interfaces/import';
import { IntegrationFile } from 'interfaces/integrations';
import * as faro from 'lib/observability';
import { instrumentAction } from 'lib/observability';
import { ReaderMode, readerStoreActions } from 'modules/listening/features/reader/stores/readerStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { ListenableContent } from 'modules/sdk/lib';
import { createTrueInstantListeningListenableContent } from 'modules/sdk/lib/facade/listenableContent/factory';
import { useDispatch, useSelector } from 'store';
import { actions as importActions } from 'store/import';
import { actions as libraryActions } from 'store/library';
import { actions as mainPageActions } from 'store/mainPage';
import { actions as toastActions } from 'store/toast';
import { logSegmentEvent, setTimeToPlay } from 'utils/analytics';

import { SDKError } from '@speechifyinc/multiplatform-sdk/api/util';

import { importItem, ImportOptions, ImportState, LegacyImportState } from '../upload/import';
import { getRecordType, InstantListeningSupportedData, isInstantListeningV2Supported, isIntegrationFile, isTextImport, isUrlImport } from '../utils';
import { usePdfUpsell } from './usePdfUpsell';

export interface ImportInfo {
  fileName: string;
  mimeType: string;
  fileSize: number;
}

function getImportInfo(data: IntegrationFile | File | TextImport | UrlImport): ImportInfo {
  if (isTextImport(data)) {
    return { fileName: data.title, mimeType: 'text', fileSize: 0 };
  }

  if (isUrlImport(data)) {
    return { fileName: '', mimeType: 'web_link', fileSize: 0 };
  }

  if (data instanceof File) {
    return { fileName: data.name, mimeType: data.type, fileSize: data.size };
  }

  const isGoogleDoc = data.fileExtension === 'gdoc';
  const fileName = isGoogleDoc ? `${data.name}.pdf` : data.name;
  const mimeType = isGoogleDoc ? ALLOWED_MIME_TYPES.PDF : data.mimeType;
  const fileSize = data.sizeBytes;

  return { fileName, mimeType, fileSize };
}

export type ImportFileData =
  | {
      data: File | TextImport | UrlImport;
      getFileUrl?: never;
      getFileBlob?: never;
    }
  | {
      data: IntegrationFile;
      getFileUrl: () => Promise<string | null>;
      getFileBlob: (filename: string, mimeType: string) => Promise<File>;
    };

export type ImportFileOptions = ImportFileData & {
  instantListening?: boolean;
  source?: string;
  onConverted?: () => void;
};

const startListeningV2 = (dispatch: ReturnType<typeof useDispatch>, listenableContent: ListenableContent) => {
  // TODO(overhaul, albertusdev): Refactor determineReaderMode to infer from listenableContent automatically, currently forgetting to call this will cause infinite loading state.
  const readerMode = readerStoreActions.determineReaderMode(listenableContent);

  profilingStoreActions.navigate(); // This is a temporary hack to make sure the instant listening measurement is not inflating other measurements.
  profilingStoreActions.startMeasurement(
    readerMode === ReaderMode.BOOK ? MeasurementKey.instantListeningBookReaderReady : MeasurementKey.instantListeningClassicReaderReady,
    {
      listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.ENABLED
    }
  );
  dispatch(
    mainPageActions.setInstantListening({
      flowStartTimestamp: performance.now(),
      isLoading: false,
      listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.ENABLED,
      details: { listenableContent: listenableContent }
    })
  );
};

const maybeStartTrueInstantListeningV2 = (dispatch: ReturnType<typeof useDispatch>, data: InstantListeningSupportedData): ListenableContent | null => {
  const listenableContent = createTrueInstantListeningListenableContent(data);
  if (!listenableContent) {
    return null;
  }
  startListeningV2(dispatch, listenableContent);
  return listenableContent;
};

export function useImportFile(): (options: ImportFileOptions) => Promise<string | null> {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkForUpsell } = usePdfUpsell();
  const folderId = useSelector(state => state.library.currentFolderId) ?? undefined;

  const handleFileImport = useCallback(
    async (options: ImportFileOptions): Promise<string | null> => {
      const { data: importData, source, onConverted, instantListening = true, getFileUrl, getFileBlob } = options;
      const listeningOverhaulVariant = await getFeatureVariant(FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL);
      const isOverhaul = listeningOverhaulVariant === WebAppListeningExperienceOverhaulVariant.ENABLED;

      const { isPDF } = await importSDK();
      const shouldCheckForUpsell = isIntegrationFile(importData) || importData instanceof File || (isUrlImport(importData) && (await isPDF(importData.url)));
      if (shouldCheckForUpsell && (await checkForUpsell())) {
        return null;
      }

      const importInfo = getImportInfo(importData);
      const { fileName, mimeType, fileSize } = importInfo;

      const isImportForLegacyListening = (importState: ImportState): importState is LegacyImportState => {
        return !isOverhaul;
      };

      try {
        const data = getFileUrl ? ((await getFileUrl()) ?? (await getFileBlob(fileName, mimeType))) : importData;

        let instantListeningV2Content: ListenableContent | null = null;

        // check if we can start a new instant listening experience right away.
        if (instantListening && isOverhaul && isInstantListeningV2Supported(data)) {
          instantListeningV2Content = maybeStartTrueInstantListeningV2(dispatch, data);
        }

        const importOptions: ImportOptions = {
          folderId,
          instantListening,
          ...(isOverhaul
            ? {
                isListeningOverhaul: true,
                listenableContent: instantListeningV2Content
              }
            : {
                isListeningOverhaul: false
              })
        };
        const importState = await instrumentAction(
          () => importItem({ data, name: fileName, mimeType, source, size: fileSize }, importOptions), //
          'importItem'
        );

        if (instantListening) {
          if (isImportForLegacyListening(importState)) {
            setTimeToPlay('file-import', getRecordType(mimeType));
            dispatch(
              mainPageActions.setInstantListening({
                flowStartTimestamp: performance.now(),
                isLoading: true,
                listeningOverhaulVariant: listeningOverhaulVariant
              })
            );
            importState.completed.then(readingInfo => {
              dispatch(mainPageActions.updateInstantListening({ isLoading: false, details: { readingInfo } }));
            });
          } else {
            // If we haven't started instant listening yet, and we have direct reference to the File, start listening early instead of waiting for the import to finish.
            if (!instantListeningV2Content) {
              dispatch(
                mainPageActions.setInstantListening({
                  flowStartTimestamp: performance.now(),
                  isLoading: true,
                  listeningOverhaulVariant: listeningOverhaulVariant
                })
              );
              if (importState.importSource.data instanceof File) {
                instantListeningV2Content = maybeStartTrueInstantListeningV2(dispatch, importState.importSource.data);
              } else {
                importState.completed.then(listenableContent => {
                  instantListeningV2Content = listenableContent;
                  startListeningV2(dispatch, listenableContent);
                });
              }
            }
          }
          dispatch(importActions.closeImportDialog());
        }

        onConverted?.();

        if (data instanceof File || isIntegrationFile(data)) {
          logSegmentEvent('web_app_file_uploaded', {
            type: fileName.split('.').slice(-1)[0],
            size: fileSize,
            source
          });
        }

        const itemId = await importState.imported;

        if (instantListeningV2Content) {
          instantListeningV2Content.onItemIdReady(itemId);
        }

        dispatch(libraryActions.fetchItemsCount());

        if (instantListening) {
          dispatch(libraryActions.fetchItem({ itemId }));
          if (isOverhaul && !instantListeningV2Content) {
            navigate(`/item/${itemId}`);
          }
        }

        dispatch(
          toastActions.add({
            title: t('Item has been imported successfully'),
            type: 'success'
          })
        );

        return itemId;
      } catch (e) {
        faro.logError(e as Error, ErrorSource.FILE_IMPORT);

        if (e instanceof SDKError.OtherException) {
          if (e.exception.message === 'INVALID_PASSWORD') {
            logSegmentEvent('password-protected-document-fail');
          }
        } else if (e instanceof Error) {
          if ('stderr' in e && typeof e.stderr === 'string' && e.stderr.includes('This file requires a password for access')) {
            logSegmentEvent('password-protected-document-fail');
          }
        }

        dispatch(mainPageActions.setInstantListening(null));
        dispatch(
          toastActions.add({
            title: t('Error'),
            description: t('error_processing_document'),
            type: 'error'
          })
        );

        return null;
      }
    },
    [dispatch, folderId, t, checkForUpsell, navigate]
  );

  return handleFileImport;
}
