import React, { useEffect } from 'react';

import Stars from 'assets/icons/stars';
import Button from 'components/elements/Button';
import Modal from 'components/elements/Modal';
import { TextAreaInput, TextInput } from 'components/elements/TextInput';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logEvent } from 'lib/observability';
import { useForm } from 'react-hook-form';
import { DOCUMENT_TITLE_MAX_LENGTH } from 'utils/constants';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

interface TextModalProps {
  onCancel: () => void;
  onSubmit: (title: string, text: string) => void;
  open: boolean;
}

export const TextModal: React.FC<TextModalProps> = ({ onCancel, onSubmit, open }) => {
  const { t } = useTranslation('common');

  const schema = yup.object().shape({
    text: yup.string().required(t('Valid text is required'))
  });
  type TextFormFields = { text: string; title: string };

  const {
    formState: { errors },
    handleSubmit: handleSubmitWrapper,
    register,
    setFocus
  } = useForm<TextFormFields>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFocus('title');
      }, 250);
    }
  }, [setFocus, open]);

  const handleSubmit = async (data: TextFormFields) => {
    onSubmit(data.title, data.text);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <form onSubmit={handleSubmitWrapper(handleSubmit)}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Paste Text')}</h3>
            </div>

            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                {t('Title')}
              </label>
              <div className="mt-1">
                <TextInput
                  error={errors.title}
                  maxLength={DOCUMENT_TITLE_MAX_LENGTH}
                  // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                  name="title"
                  placeholder={t('Optional')}
                  {...register('title')}
                />
              </div>
            </div>

            <div>
              <label htmlFor="text" className="block text-sm font-medium text-gray-700">
                {t('Text')}
              </label>
              <div className="mt-1">
                <TextAreaInput error={errors.text} {...register('text')} />
              </div>
            </div>
          </div>

          <div className="item-center flex justify-between  bg-gray-50 px-4 py-3 text-right">
            <div>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://studio.speechify.com/dashboard"
                onClick={() => logEvent('voiceover_link_clicked', {})}
                target="_blank"
                className="flex items-center"
              >
                <Stars />
                <span className="gradient-text font-bold">{t('Create Voice Over')}</span>
              </a>
            </div>
            <div className="flex gap-2">
              {' '}
              <Button title={t('Submit')} type="submit" />
              <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TextModal;
